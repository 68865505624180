"use strict"
import { myAxios } from '@/custom_axios'

export class AccountApi {
    /**
     * Return current account
     * @returns {Promise<*>}
     */
    static async getAccount () {
        return myAxios.get(`/api/secure/account/`)
    }

    static async putPassword (changePassData) {
        return myAxios.put(`/api/secure/account/password`, changePassData)
    }

    /**
     *
     * @param {Object.<string, string>} credentials
     * @return {Promise<AxiosResponse<any>>}
     */
    static async postCredentials (credentials) {
        return myAxios.post(`/api/auth/login`, credentials)
    }

    static async postAccount (accountData) {
        return myAxios.post(`/api/auth/account`, accountData)
    }

    static async postAccountInfoLost (postData) {
        return myAxios.post(`/api/auth/account/pass_key`, postData)
    }

    static async putAccountLostPassReplace (keyPass, postData) {
        return myAxios.put(
            `/api/auth/account/${keyPass}/pass`,
            postData
        )
    }

    /**
     *
     * @param {int} id
     * @return {Promise<AxiosResponse<any>>}
     */
    static async putMoney (id) {
        return myAxios.put(`/api/secure/account/money/${id}`, {})
    }

    static async getCaptcha () {
        return myAxios.get(`/api/auth/captcha/`)
    }
}
