<template>
  <div class="row">
    <div class="col-12">
      <div
        id="game"
        class="row justify-content-center"
      >
        <div class="col-xl-10 col-lg-10  col-md-12">
          <h2>{{ `title_game` | trans }}</h2>
        </div>
        <div class="col-xl-10 col-lg-8 col-md-12">
          <game-install />
          <game-update />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
'use strict'
import { Common } from "@/common"
import GameInstall from "@/components/modules/game/GameInstall.vue"
import GameUpdate from "@/components/modules/game/GameUpdate.vue"

export default {
    components: { GameUpdate, GameInstall },
    metaInfo () {
        return Common.getMetaInfo(this.$route.name)
    },
    computed: {
        character () {
            return this.$store.getters.getServiceCharacter
        }

    }
}
</script>
